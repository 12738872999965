var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-main",
        { staticClass: "w-100", attrs: { fluid: "" } },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.GetWhatToShow == "view",
                  expression: "GetWhatToShow == 'view'",
                },
              ],
              staticClass: "w-100 pa-8",
            },
            [
              _c("kview", {
                attrs: { form: "irsaliye", viewname: _vm.viewname },
                on: {
                  rowclicked: function ($event) {
                    return _vm.ViewClicked($event, "view")
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.GetWhatToShow == "form",
                  expression: "GetWhatToShow == 'form'",
                },
              ],
              staticClass: "w-100 pa-8",
            },
            [
              _c(
                "v-toolbar",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "", icon: "" },
                      on: { click: _vm.BackToView },
                    },
                    [_c("v-icon", [_vm._v("arrow_back")])],
                    1
                  ),
                  _c("v-toolbar-title", [_vm._v("Sabit Varlık Tanımlama")]),
                  _c("v-spacer"),
                ],
                1
              ),
              _c(
                "v-form",
                {
                  ref: "form",
                  staticClass: "w-100",
                  attrs: { "lazy-validation": _vm.lazy },
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c("p", [_vm._v("Srtatatat")]),
                      _c("p", {
                        domProps: { textContent: _vm._s(_vm.GetState) },
                      }),
                      _c("dynoform", {
                        attrs: {
                          header: "Varlık Tanımlama",
                          attributes: _vm.GetFormAttributes,
                          tabname: _vm.Tab - 1,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.UpdateFormField($event, "formdata")
                          },
                          changeGrid: function ($event) {
                            return _vm.UpdateExtData($event, "formdata")
                          },
                          click: function ($event) {
                            return _vm.FormButtonClicked($event)
                          },
                          SearchInputChanged: function ($event) {
                            return _vm.SearchInputChanged($event, "formdata")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _c(
                            "v-dialog",
                            {
                              staticClass: "col-xs-6",
                              attrs: { persistent: "", "max-width": "290" },
                              model: {
                                value: _vm.actionResultDialog,
                                callback: function ($$v) {
                                  _vm.actionResultDialog = $$v
                                },
                                expression: "actionResultDialog",
                              },
                            },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "headline" },
                                    [_vm._v("İşleminiz tamamlandı")]
                                  ),
                                  _vm.GetFormMessages.errorMessage == ""
                                    ? _c("v-card-text", [
                                        _vm._v("İşlem Başarılı"),
                                      ])
                                    : _c("v-card-text", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.GetFormMessages.errorMessage
                                            ) +
                                            " "
                                        ),
                                      ]),
                                  _vm.GetFormMessages.infoMessage != ""
                                    ? _c("v-card-text", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.GetFormMessages.infoMessage
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "red darken-1",
                                            text: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.CloseActionResultDialog()
                                            },
                                          },
                                        },
                                        [_vm._v("Kapat")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-footer",
            { staticClass: "primary darken-4", attrs: { inset: "", app: "" } },
            [
              _vm._l(_vm.GetFormAction, function (action) {
                return !_vm.actionBarWait
                  ? _c(
                      "v-btn",
                      {
                        key: action.action,
                        style: _vm.GetActionButtonStyle(action),
                        on: {
                          click: function ($event) {
                            return _vm.FormAction(action)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(action.label) + " ")]
                    )
                  : _vm._e()
              }),
              _vm.actionBarWait
                ? _c("v-toolbar-title", { staticClass: "white--text" }, [
                    _vm._v("Lütfen Bekleyiniz"),
                  ])
                : _vm._e(),
              _c("v-spacer", { staticClass: "pl-2" }),
              _c("v-toolbar-title", { staticClass: "white--text" }, [
                _vm._v("SEY"),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }